import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import SEO from '../components/seo'
import Layout from '../components/layout'
import mapPrague from '../images/maps/prague.png'
import mapCzech from '../images/maps/czech.png'

const MapPage = () => (
	<Layout>
		<SEO title="Region map" />
		<div className="container px-3 py-3 pt-md-5 pb-md-4 text-center">
			<h1 className="display-4 mb-4">Prague</h1>
			<p className="lead map-lead mb-5 mx-auto">
				All <Link to="/courses/">golf courses</Link> are situated 15-45 minutes
				from Prague city center. You can easily combine golf with shopping,
				sightseeing and partying downtown.
			</p>

			<div className="position-relative map-prague mx-auto">
				<img
					className="mb-2 w-100"
					src={mapPrague}
					alt="Map of Prague region"
				/>
				<OutboundLink
					href="https://www.grcm.cz/en/"
					title="Golf Resort Black Bridge"
					className="map-link-bb"
				/>
				<OutboundLink
					href="https://www.praguecitygolf.cz/"
					title="Prague City Golf Zbraslav"
					className="map-link-zbraslav"
				/>
				<OutboundLink
					href="http://www.loretagolf.cz/"
					title="Loreta Golf Club Pyšely"
					className="map-link-loreta"
				/>
				<OutboundLink
					href="https://www.golfmstetice.cz/"
					title="Golf Club Mstětice"
					className="map-link-mstetice"
				/>
				<OutboundLink
					href="https://www.gcbr.cz/"
					title="Golf Beřovice"
					className="map-link-berovice"
				/>
			</div>

			<hr className="featurette-divider" />

			<h1 className="display-4 mb-4">Czech Republic</h1>
			<p className="lead map-lead mb-5 mx-auto">
				Prague - being Czech capital city - is easily accessible by car from
				Germany, Austria, Poland, and Slovakia, or by plane from all major
				airports in the world.
			</p>

			<img className="mb-2 w-100" src={mapCzech} alt="Map of Czech Republic" />

			<hr className="featurette-divider" />
		</div>
	</Layout>
)

export default MapPage
